@import "../../../assets/sass/variables";
@import "../../../assets/sass/mixins";

.project-heading {
  background: $magnoliaWhite;
  padding: 1.875rem;
  padding-bottom: 0px;
  border-bottom: 1px solid rgba($textSecondaryColor, $alpha: 0.3);
  margin-bottom: 20px;

  &-text {
    font-size: 18px;
    font-family: "Font-Semibold", Arial, Sans-Serif;
    background: linear-gradient(90deg, $linkColor 1.69%, $purpleColor 101.55%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: 15px;
  }

  .ant-tabs-top {
    & > .ant-tabs-nav {
      margin-bottom: 0px;
    }
  }
}

.summary-grid {
  display: grid;
  grid-template-columns: 1fr 320px;
  column-gap: 30px;

  .active-check {
    @include font_size_color(null, $green, null, null, Font-Bold);
  }

  .collabarators {
    h3 {
      padding: 20px;
      background: linear-gradient(106.08deg, $purpleColor 0%, $darkBlue 55.46%);
      border-radius: 4px 4px 0px 0px;
      @include font_size_color(null, $baseColor, null, null, Font-Semibold);
    }

    &-list {
      border-radius: 0px 0px 4px 4px;
      background: $magnoliaWhite;
      border: 1px solid rgba($textSecondaryColor, $alpha: 0.2);
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
    }
  }
}

.document {
  &-container-table {
    &-status {
      width: 19% !important;
    }

    &-name {
      width: 18% !important;
      padding: 15px !important;

      .ant-table-column-sorter-inner {
        visibility: hidden;
      }
    }

    &-updatedAt {
      width: 15.33% !important;
    }

    &-createdAt {
      width: 15.33% !important;
    } 

    &-timeTaken {
      width: 10.33% !important;
    }

    &-DocumentUUID {
      width: 20.33% !important;

      .ant-table-column-sorter-inner {
        visibility: hidden;
      }
    }

    &-totalpages {
      width: 7.33%;
    }

    .ant-table {
      .ant-table-tbody {
        max-height: calc(100vh - 390px);
      }
    }

    &-search {
      .ant-input {
        background: #ffffff;
      }

      .ant-input-search-button {
        border-radius: 0px 6px 6px 0px !important;
      }
    }

    &-checkbox {
      width: 2%;
      padding: 10px !important;
    }

    .ant-table-container table .ant-table-cell:last-child {
      width: 10%;
      text-align: center;
    }

    .doc-name {
      width: 18%;
    }
    .mapping-id{
      width: 15%;
    }
    .doc-editcode {
      width: 15%;
    }
    .mapping-action{
      width: 30%;
    }
  }

  &-modal-table {
    .ant-table-container table .ant-table-cell:first-child {
      width: 13%;
      text-align: center;
    }

    .ant-table-container table .ant-table-cell:nth-child(4) {
      width: 25%;
    }

    .ant-table-container table .ant-table-cell:last-child {
      width: 20%;
      text-align: center;
    }
  }
}

.status {
  border: 1px solid $tabBgColor;
  border-radius: 3px;
  box-shadow: 0 2px 15px $boxShadowColor;
  display: inline-block;
  font-family: Font-Medium;
  font-size: 12px;
  padding: 6px 10px;
  word-break: break-word;
}

.paginationDiv {
  margin-left: auto !important;
}

.page-no-column {
  width: 80px;
}

.updated-at-column {
  width: 200px;
}

.status-column {
  width: 320px;
}

.ant-table-wrapper .ant-table-expand-icon-col {
  width: 0px !important;
}

.tabHeader {
  display: flex;
  margin-bottom: 2em;

  &-delete {
    display: flex;
    gap: 16px;
    margin-left: auto !important;
  }

  &-selected {
    display: flex;
    align-items: center;
  }
}

.expanded-row-content {
  display: flex;
  gap: 40px; 

  .session-id,
  .status-description {
    max-width: 250px; // Fixed width for the Session ID and Status
    min-width: 150px;
    display: flex;
    justify-content: center;
  }

  .json-content {
    flex: 1; // Takes up the rest of the space
    max-width: 100%; 
    overflow: auto; // Scroll if the content is too large
  }
}

.text-wrap-class {
  white-space: normal !important;  
  word-break: break-word;
  overflow-wrap: break-word; 
}


